import React, { Component } from "react";

import Layout from '../layout';

import Operation from './../pages/images/operation.png';

import Footer from './footer';

export default class operations extends Component {
    render() {
        return (
            <Layout>
                <div className="main">
                    <section id="comment_block" className="comment_block" data-scroll-index="5">

                        <div className="container">

                            <div className="row">

                                <div className="col-12">
                                    <div className="tac"><i className="fas fa-quote-right"></i></div>
                                    <div className="slider_comment">

                                        <div className="operation_body" >
                                            <p>L-CEBIC is operated by the Samburu County
                                                Government through the County Ministry
                                                of Tourism, Trade and Enterprise
                                                Development.</p>


                                            <div> <img src={Operation} alt="" style={{ height: '200px', width: '400px', borderRadius: 0 }} /></div>
                                        </div>







                                    </div>

                                </div>

                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </Layout>
        )
    }
}
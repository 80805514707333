import React, { Component } from "react";

import Layout from "../layout";

import Landing from './images/landing.jpg'

import Solar from './images/DSC_0555.JPG';

import Page from './images/Page.jpg'

// import { Link } from 'react-router-dom'

import { Carousel } from 'react-bootstrap';

export default class index extends Component {


    render() {

        console.log(this.state)
        return (
            <Layout>
                <div  >
                    <Carousel >
                        <Carousel.Item>
                            <img
                                className="d-block w-100 "
                                src={Landing}
                                style={{height:'100vh',objectFit: 'cover'}}
                                alt="First slide"
                            />
                            <Carousel.Caption>
                                <h3>CEBIC</h3>
                                <p>Lesidai Community Education, Business and Information Centre.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={Solar}
                                style={{height:'100vh',objectFit: 'cover'}}
                                alt="Third slide"
                            />

                            <Carousel.Caption>
                                <h3>Enhancing climate change resilience</h3>
                                <p>Enhancing climate change resilience through promoting access to information and creating business opportunities for vulnerable communities.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={Page}
                                style={{height:'100vh',objectFit: 'cover'}}
                                alt="Third slide"
                            />

                            <Carousel.Caption>
                                <h3>The L-CEBIC</h3>
                                <p>The L-CEBIC is operationalized through the collaboration of the Ministry of Environment and Forestry, Samburu County Government and the community through the Malaso ‘A’ Group Ranch</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>

                </div>
                {/*<header id="top_block" className="bg_img"  data-background={Landing}>*/}
                {/*<div className="creative_bg_3"></div>*/}
                {/*<div className="container">*/}
                {/*<div className="row">*/}

                {/*<div className="col-12">*/}
                {/*<h1 className="fadeInDown wow">CEBIC</h1>*/}
                {/*<h5 className="fadeInUp wow"></h5>*/}

                {/*<div className="row justify-content-center">*/}
                {/*<a href="#about_block" className="order conversion fadeInLeft wow">Learn more</a>*/}
                {/*<a href="#contact_block" className="order_2 fadeInRight wow">Contact us</a>*/}
                {/*</div>*/}
                {/*</div>*/}

                {/*</div>*/}
                {/*</div>*/}
                {/*</header>*/}
            </Layout>
        )
    }
}
import Service1 from './images/services/service1.PNG';

import Service2 from './images/services/service2.PNG';

// import Service3 from './images/services/service3.PNG';

import Service4 from './images/services/service4.PNG';

import Service5 from './images/services/service5.PNG';

import Service6 from './images/services/service6.PNG';

// import Service7 from './images/services/service7.png';

// import Service8 from './images/services/service8.png';

import Service9 from './images/services/service9.png';

// import Service10 from './images/services/service10.png';

import Service11 from './images/services/service11.png';

// import Service12 from './images/services/service12.png';


export const ServicesData = [
    {
        image: Service1,
        title: 'Multi-purpose Retail Shop',
        desc: 'Mobile Money, Agrovet,Renewable energy Products,Climate Change Information)'
    },
    {
        image:Service2,
        title: 'ICT and e-government services',
        desc: 'Provision of information services and internet access.'
    },
    {
        image:Service4,
        title: 'Community Library',
        desc: 'Our library is a source of accessing information and knowledge creation. Visit our library to learn about Samburu, Pokot, and Turkana culture'
    },
    {
        image:Service5,
        title: 'Cultural / Artefact Centre',
        desc: 'Cultural museum, art and exhibition of traditional technologies. Also, venue for selling Samburu beads, clothes, necklaces among others.'
    },
    {
        image:Service6,
        title: 'Meeting and Training Facilities',
        desc: 'Naturally lit conference room that is equipped with an audio visual and conference support equipment. Ideal venue for workshops, conferences, seminars and retreats.'
    },
    {
        image:Service9,
        title: 'Catering Services',
        desc: 'We offer catering services during workshops, conferences, seminars and retreats. We also offer outside careering within Samburu County.'
    },
    {
        image:Service11,
        title: 'Outdoor Amphitheartre',
        desc: 'Our Amphitheatre is ideal for cultural events, weddings and public gatherings.'
    },
]

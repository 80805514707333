import React, {Component} from "react";



export default class errorPage extends Component {
    render(){
        return(
            <div className="main" style={{width:'100%',height:'100%', display:'flex',justifyContent:'center',flexDirection:'column',padding:'20%'}}>
                <h1>404</h1>
                <p>can not find this page on this server</p>
            </div>
        )
    }
}
import React, { Component } from 'react';

import Layout from '../layout';

import './style.css';


import Y1 from './images/whyUs/y1.PNG';

import Y2 from './images/whyUs/y2.PNG';

import Y3 from './images/whyUs/y3.PNG';

import Y4 from './images/whyUs/y4.PNG';

import Y5 from './images/whyUs/y5.PNG';

import Footer from './footer';

export default class why_us extends Component {


    render() {
        return (
            <Layout >
                <div className="main">
                    <section className="services_block" data-scroll-index="2">
                        <div className="container">
                            <div className="row justify-content-center">

                                <div className="col-12">
                                    <h2 className="title"><span>Why L-CEBIC</span></h2>
                                </div>

                                <div className="col-12 col-md-4 mb30">
                                    <div className="services_box">
                                        <div className="icons_box">
                                            <img src={Y1} alt="ken" />
                                        </div>
                                        {/*<h3>Retail Shop</h3>*/}
                                        <p>To promote access to
                                            information and create
                                            business opportunities for
                                            vulnerable communities</p>
                                    </div>
                                </div>

                                <div className="col-12 col-md-4 mb30">
                                    <div className="services_box">
                                        <div className="icons_box">
                                            <img src={Y2} alt="ken" />
                                        </div>
                                        {/*<h3>ICT and e~Advisory Services</h3>*/}
                                        <p>To enhance access to clean
                                            and efficient energy systems.</p>
                                    </div>
                                </div>

                                <div className="col-12 col-md-4 mb30">
                                    <div className="services_box">
                                        <div className="icons_box">
                                            <img src={Y3} alt="ken" />
                                        </div>
                                        {/*<h3>Training and Demo Centre</h3>*/}
                                        <p>To facilitate learning and
                                            skills transfer among
                                            communities</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 mb30">
                                    <div className="services_box">
                                        <div className="icons_box">
                                            <img src={Y4} alt="ken" />
                                        </div>
                                        {/*<h3>Training and Demo Centre</h3>*/}
                                        <p>To act as linkage and referral
                                            point with other organisations,
                                            communities and other
                                            stakeholders</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 mb30">
                                    <div className="services_box">
                                        <div className="icons_box">
                                            <img src={Y5} alt="ken" />
                                        </div>
                                        {/*<h3>Training and Demo Centre</h3>*/}
                                        <p>To enhance documentation and
                                            dissemination of local content</p>
                                    </div>
                                </div>
                                {/*<div className="col-12 col-md-4 mb30">*/}
                                {/*<div className="services_box">*/}
                                {/*<div className="icons_box">*/}
                                {/*<i className="fas fa-chart-line"></i>*/}
                                {/*</div>*/}
                                {/*<h3>Training and Demo Centre</h3>*/}
                                {/*<p>To facilitate learning and*/}
                                {/*skills transfer among*/}
                                {/*communities</p>*/}
                                {/*</div>*/}
                                {/*</div>*/}

                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </Layout>
        )
    }
}
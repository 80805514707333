import React, { Component } from 'react';

import Layout from '../layout';

import './style.css';

import { ServicesData } from './includes';

import Footer from './footer';


export default class services extends Component {




    render() {
        return (
            <Layout >
                <div className="main">
                    <section className="services_block" data-scroll-index="2">
                        <div className="container">
                            <div className="row justify-content-center">

                                <div className="col-12">
                                    <h2 className="title"><span>Our Services</span></h2>
                                </div>
                                {ServicesData.map((services, index) => (
                                    <div className="col-12 col-md-4 mb30">
                                        <div className="services_box">
                                            <div className="service_box">
                                                <img src={services.image} alt="" />
                                            </div>
                                            <h3>{services.title}</h3>
                                            <p>{services.desc}</p>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </Layout>
        )
    }
}
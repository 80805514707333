import React, {Component} from "react";

import Layout from "../layout";

import Footer from './footer';

export default class contact extends Component {


    render(){




        return(
            <Layout>
                <div className="main">
                    <section id="contact_block" className="contact_block" >
                        <div className="container">
                            <div className="row">

                                <div className="col-12">
                                    <h2 className="title"><span>Contact us</span></h2>
                                </div>


                                <ul className="list_contact">
                                    <li>
                                        <div className="icon_box"><i className="far fa-map"></i></div>
                                        <div className="box_text" >
                                            <div className="titles">Adderess</div>
                                            The County Government of Samburu
                                            Former Town Council Offices,
                                            Maralal, Samburu
                                            P.O. Box 3 – 20600, Maralal, Kenya.

                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon_box"><i className="fas fa-phone"></i></div>
                                        <div className="box_text">
                                            <div className="titles">Tel</div>
                                            <a href="tel:212-363-3200">(+254) 7065 62456,
                                                <br/>(+254) 065 62456</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon_box"><i className="fas fa-envelope"></i></div>
                                        <div className="box_text">
                                            <div className="titles">Email</div>
                                            <a href="mailto:contact@example.com">cebic@info.com</a>
                                        </div>
                                    </li>
                                </ul>


                                <div className="col-12">
                                    <form id="form" action="#">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-md-12 col-lg-6">
                                                <input type="text" name="name" placeholder="Name*" required/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-12 col-lg-6">
                                                <input type="email" name="email" placeholder="Email*" required/>
                                            </div>
                                            <div className="col-12">
                                            <textarea name="messages" cols="30" rows="10"
                                                      placeholder="Your message"></textarea>

                                                <div className="box_order"><input type="submit" value="Send"
                                                                                  className="order"/></div>
                                                <input type="hidden" name="formData" value="Feedback"/>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
<Footer/>
            </Layout>
        )
    }
}
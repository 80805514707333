import React, { Component } from "react";

import Layout from "../layout";

import About from "./images/about.png";

import Footer from './footer';

// import   './style.css'



export default class about extends Component {
    render() {
        return (
            <Layout>
                <div className="main">
                    <section id="about_block" className="about_block" data-scroll-index="1">

                        <div className="container">
                            <div className="row align-items-center">

                                <div className="col-12">
                                    <h2 className="title"><span>About Us</span></h2>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="img_box">
                                        <img src={About} alt="" style={{ height: 540, objectFit: 'cover', width: 540 }} />
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="contents_box">
                                        {/*<h3>Our Story</h3>*/}
                                        <p>
                                            Lesidai Community Education Business and
                                            Information Centre (L-CEBIC) is a hub for
                                            information sharing and livelihood support that
                                            serves as a model centre for the counties in
                                            the arid and semi-Arid regions to reduce their
                                            climate vulnerability.
                        </p>
                                        <p>
                                            L-CEBIC comprises of an ultra-modern building
                                            which blends well with the local topography and
                                            is also based on the green buildings concept.
                                            The Ministry of Environment and Forestry
                                            through the USAID-UNDP funded Low Emission
                                            and Climate Resilient Development (LECRD)
                                            Project supported the establishment of the
                                            L-CEBIC
                        </p>
                                        <p>
                                            Lesidai community through Malasso ‘A Group
                                            Ranch provided 3 acres of their public utility
                                            land for the establishment of the Centre
                        </p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>


                <Footer />

            </Layout>
        )
    }
}

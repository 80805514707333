import React, { Component } from "react";

import Layout from "../layout";

import "./style.css";

import Service1 from "./images/pdf.jpg";

import Cebics from "./../Downloads/CEBIC.pdf";

import Avocado from "./../Downloads/avocado-production-cultivation.pdf";

import Bamboo from "./../Downloads/Bamboo.pdf";


import Bio_fertilizer from "./../Downloads/Bio-fertilizer.pdf";

import Bush_rye from "./../Downloads/Bush-Rye.pdf";

import Cherp from "./../Downloads/CHERPbrochure.pdf";

import Contribution from "./../Downloads/CONTRIBUTIONOFPELISININCREASINGTREECOVERANDCOMMUNITY.pdf";

import Cut_worm from "./../Downloads/Cut-worm-on-kales-kenya.pdf";

import Ecosystem from "./../Downloads/Ecosystempayment.pdf";

// import Ecosystem2 from "./../Downloads/Ecosystempg2.pdf";

import FallArmy from "./../Downloads/Fall-Army-Worm-brochure-april-2017.pdf";

import General_gpe from "./../Downloads/general_gpe_presentation_april_2019.pdf";

import Grevillea from "./../Downloads/GrevillearobustainKenyaflyer.pdf";

import Information_source from "./../Downloads/Information-sources.pdf";

import Lab_info from "./../Downloads/LaboratoryInformation.pdf";

import Melia from "./../Downloads/Melia Guideline.pdf";

import NewEucalyptus from "./../Downloads/neweucalyptus.pdf";

import Papaya from "./../Downloads/Papaya_Black_Spot_Ruth_Amata_Aug_2016.pdf";

import Pestanddiseases from "./../Downloads/Pestsanddiseases.pdf";

import PlantParasitic from "./../Downloads/Plant-Parasitic-Nematodes-and-Food-Security-in-sub-Saharan-Africa-SSA.pdf";

import PMDG from "./../Downloads/PMDG-banana-cigar-endrot-oct2013-final-revised.pdf";

import PMDG2 from "./../Downloads/PMDG-Banana-Fusarium-Wilt-Kenya-July2013-final.pdf";

import PMDG_Bean from "./../Downloads/PMDG-bean-rust-Kenya-July2013_v2-final.pdf";

import PMDG_Cabbage from "./../Downloads/PMDG-cabbage-and-kale-black rot-Kenya-July2013.pdf";

import PMDG_Coffe_cBd from "./../Downloads/PMDG-coffee-CBD-Kenya-July2013_v3-final.pdf";

import PMDG_Coffe_green from "./../Downloads/PMDG-coffee-green-scale-Kenya-July2013-final.pdf";

import PMDG_Tomatoes_blosom from "./../Downloads/PMDG-tomato-blossom-end-rot-Kenya-Final-2014.pdf";

import PMDG_Tomatoes_Bacteria from "./../Downloads/PMDG-tomato-bacterial_wilt-Kenya-July2013-v4-final.pdf";

import Usefull from "./../Downloads/USEFULTREESSUITABLEFORCENTRALHIGHLANDSECO-REGION.pdf";

import Animal_health from "./../Downloads/down/AnimalHuman Health PROGRAM.pdf";

import Cow_peas from "./../Downloads/down/Cow peas.pdf";

import Growing_maize from "./../Downloads/down/Growing Maize for food and fodder.pdf";

import Leptospirosis from "./../Downloads/down/Leptospirosi.pdf";

import Most_Milk from "./../Downloads/down/Making the most milk.pdf";

import Managing_Army_worm from "./../Downloads/down/Managment of armyworm.pdf";

import Mbaazi from "./../Downloads/down/Mbaazi.pdf";

import Plant_parasite from "./../Downloads/down/Plant-Parasitic-Nematodes-and-Food-Security-in-sub-Saharan-Africa-SSA.pdf";

import Poster_maize from "./../Downloads/down/Poster maize storage pests.pdf";

import Q_fever from "./../Downloads/down/Q-Fever.pdf";

import Footer from './footer';


export default class downloads extends Component {

    constructor(props) {

        super(props);

        this.state = {

            downloads: [],


        }
    }

    render() {


        let download_links = [
            // {
            //     name: Bamboo2,
            //     title: 'KEFRI bronchure',
            //     description: 'Introduction'
            // },
            {
                name: Avocado,
                title: 'KEFRI bronchure',
                description: 'Avocado production'
            }, {
                name: Bamboo,
                title: 'KEFRI bronchure',
                description: 'Bamboo Propagation Methods'
            },
            {
                name: Bio_fertilizer,
                title: 'KEFRI bronchure',
                description: 'Production of bio-fertilizer (KEFRIFIX)'
            },
            {
                name: Bush_rye,
                title: 'Range grasses factsheet',
                description: 'Enteropogon macrostachyus (Bush Rye)'
            },

            {
                name: Cebics,
                title: 'Our Bronchure',
                description: 'Cebic(Bronchure)'
            },

            {
                name: Cherp,
                title: 'Socio-economics Policy and Governance ',
                description: 'Central Highlands ECO-Region Research Program (CHERP) '
            },

            {
                name: Contribution,
                title: 'Kenya Forestry Research Institute (KEFRI)',
                description: 'Contribution of PELIS in increasing tree cover and community livelihoods in Kenya'
            },

            {
                name: Cut_worm,
                title: 'Pest management decision Guide : Green and Yellow list ',
                description: ' Pest management decision Guide (Cutworm on Kales) '
            },

            // {
            //     name: Ecosystem2,
            //     title: 'Introduction',
            //     description: 'Ecosystem services '
            // },

            {
                name: Ecosystem,
                title: 'Payment for Ecosystem Services in Kenya ',
                description: ' Payment for Ecosystem Services in Kenya (Status and Opportunities) '
            },

            {
                name: FallArmy,
                title: 'FALL ARMY WORM (SPODOPTERA FRUGIPERDA) ',
                description: ' Fall army Worm (SPODOPTERA FRUGIPERDA)  '
            },

            {
                name: General_gpe,
                title: 'Improving learning and equity',
                description: 'Education is key to all Sustainable Development Goals'
            },
            {
                name: Grevillea,
                title: 'Growing Grevillea robusta in Kenya',
                description: ' Growing Grevillea robusta in Kenya: Management and Challenges'
            },

            {
                name: Information_source,
                title: 'Information sources for writing extension materials',
                description: 'Information sources for writing extension materials'
            },

            {
                name: Lab_info,
                title: 'Labaratory  Information Service at  KEFRI',
                description: 'Labaratory  Information Service at  KEFRI'
            },

            {
                name: Melia,
                title: 'Improved Melia Seeds',
                description: 'Improved Melia Seeds '
            },

            {
                name: NewEucalyptus,
                title: 'Growing and use of  Eucalyptus',
                description: 'Facts on Growing and use of  Eucalyptus in Kenya'
            },

            {
                name: Papaya,
                title: 'KALRO E-mimea Plant Clinic ',
                description: 'Black Spot of Papaya (Asperisporium caricae)'
            },

            {
                name: Pestanddiseases,
                title: 'Pests and Diseases ',
                description: 'Pests and diseases management on Eucalyptus trees'
            },

            {
                name: PlantParasitic,
                title: 'Plants Parasites ',
                description: 'Plant-Parasitic Nematodes and Food Security in sub-Saharan Africa (SSA)'
            },

            {
                name: PMDG,
                title: 'Pest managemt decision Guide: Green and Yellow list  ',
                description: 'Pest managemt decision Guide (Cigar end rot on banana)'
            },

            {
                name: PMDG2,
                title: 'Pest managemt decision Guide: Green and Yellow list  ',
                description: 'Pest managemt decision Guide (Fusarium Wilt or Panama Disease on Banana) '
            },

            {
                name: PMDG_Bean,
                title: 'Pest managemt decision Guide: Green and Yellow list  ',
                description: 'Pest managemt decision Guide (Bean rust)  '
            },

            {
                name: PMDG_Cabbage,
                title: 'Pest managemt decision Guide: Green and Yellow list  ',
                description: 'Pest managemt decision Guide (Black rot on cabbage)'
            },

            {
                name: PMDG_Coffe_cBd,
                title: 'Pest managemt decision Guide: Green and Yellow list  ',
                description: 'Pest managemt decision Guide (Coffee Berry Disease)'
            },

            {
                name: PMDG_Coffe_green,
                title: 'Pest managemt decision Guide: Green and Yellow list  ',
                description: 'Pest managemt decision Guide(Green scales on coffee)'
            },

            {
                name: PMDG_Tomatoes_Bacteria,
                title: 'Pest managemt decision Guide: Green and Yellow list  ',
                description: 'Pest managemt decision Guide(Bacterial wilt on tomatoes)'
            },

            {
                name: PMDG_Tomatoes_blosom,
                title: 'Pest managemt decision Guide: Green and Yellow list  ',
                description: 'Pest managemt decision Guide(Blossom end rot in tomatoes)'
            },

            {
                name: Usefull,
                title: '',
                description: 'Useful tress suitable for Central Highlands ECO-Region'
            },

            // {
            //     name: Seed_leafLets,
            //     title:'name: Seed_leafLets',
            //     description: ''
            // },

            {
                name: Animal_health,
                title: '',
                description: 'Animal and human health program'
            },

            {
                name: Cow_peas,
                title: '',
                description: 'KVU-419 (Cowpeas)'
            },

            {
                name: Growing_maize,
                title: '',
                description: 'Growing maize for food and fodder'
            },

            // {
            //     name: KVU_cow_peas,
            //     title:'Growing maize for food and fodder',
            //     description: ''
            // },

            {
                name: Leptospirosis,
                title: '',
                description: 'Leptospirosis'
            },

            {
                name: Most_Milk,
                title: '',
                description: 'Making the most milk'
            },

            {
                name: Managing_Army_worm,
                title: '',
                description: 'Managing of army worm'
            },

            {
                name: Mbaazi,
                title: '',
                description: 'Mbaazi-2 (Pigeonpea)'
            },

            {
                name: Plant_parasite,
                title: '',
                description: 'Plant-Parasitic Nematodes and Food Security in sub-Saharan Africa (SSA)'
            },

            {
                name: Poster_maize,
                title: '',
                description: 'Poster maize storage pests'
            },

            {
                name: Q_fever,
                title: '',
                description: 'Q Fever'
            },

        ]


        return (
            <Layout >
                <div className="main">
                    <section className="services_block" data-scroll-index="2">
                        <div className="container">
                            <div className="row justify-content-center">

                                <div className="col-12">
                                    <h2 className="title"><span>Our Downloads</span></h2>
                                </div>


                                <ul >{download_links.map(download => (

                                    <a target="_blank"  rel="noopener noreferrer" href={download.name}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            paddingBottom: '10px',
                                            alignItems: 'center'
                                        }}>
                                            <hr />
                                            <img src={Service1}
                                                alt="" style={{ height: '30px' }} />

                                            <li style={{ marginLeft: '10px' }}>
                                                {download.description}
                                            </li>


                                        </div>
                                    </a>



                                )
                                )}
                                </ul>

                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </Layout>
        )
    }
}

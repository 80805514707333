import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import {Route, Router, Switch} from "react-router-dom";

import createHistory from "history/createBrowserHistory";

import ErrorPage from './components/pages/errorPage';

import Operation from './components/pages/operations';

import Landing from './components/pages/index';

import Services from  './components/pages/services';

import About from './components/pages/about';

import Downloads from './components/pages/downloads';

import Contact from  './components/pages/contact';

import Why from './components/pages/why_us';

// import

function App() {
  return (
      <Router history={createHistory()}>


          <Switch>
              <Route path="/" exact component={Landing}/>

              <Route path="/our_story" exact component={About}/>

              <Route path="/our_services" exact component={Services}/>

              <Route path="/contact_us" exact component={Contact}/>

              <Route path="/Why-the-L-CEBIC" exact component={Why}/>

              <Route path="/L-CEBIC-Operation" exact component={Operation}/>

              <Route path="/our_downloads" exact component={Downloads}/>

              <Route component={ErrorPage}/>

          </Switch>
      </Router>
  );
}

export default App;

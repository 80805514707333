import React, {Component} from "react";

import {Link} from "react-router-dom";

import Logo from "./../pages/images/alinLogo.png";




export default class index extends Component {


    render() {
        return (
            <div>
                <nav className="nav_bar">
                    <div className="container" style={{height: '80px'}}>
                        <div className="row align-items-center justify-content-between">
                            <Link to="/" className="logo">
                                <img src={Logo} alt="" style={{objectFit: 'cover', height: '70px', width: '206px',marginTop:'3px',maxWidth: 'none'}}/>
                            </Link>


                            <button className="mob_button">
                                <span className="line_menu line-1"></span>
                                <span className="line_menu line-2"></span>
                                <span className="line_menu line-3"></span>
                            </button>

                            <ul className="menu" style={{color: '#000'}}>
                                <li><Link onClick={this.forceUpdate} to={{ pathname:"/"}}>Home</Link></li>
                                <li><Link to="/our_story">About</Link></li>
                                <li><Link to="/our_services">Services</Link></li>
                                <li><Link to="Why-the-L-CEBIC">Why the L-CEBIC</Link></li>
                                {/* <li><Link to="L-CEBIC-Operation" >L-CEBIC-Operation</Link></li> */}
                                <li><Link to="our_downloads" >Downloads</Link></li>
                                {/*<li><a href="#comment_block" data-scroll-nav="5">Clients</a></li>*/}
                                <li><Link to="contact_us">Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div id="overlay"
                     style={{

                         position: 'fixed', /* Sit on top of the page content */
                         display: 'block', /* Hidden by default */
                         /*width: 150px; !* Full width (cover the whole page) *!*/
                         height: 80, /* Full height (cover the whole page) */
                         top: 0,
                         left: 0,
                         right: 0,
                         bottom: 0,
                         backgroundColor: 'rgba(0,0,0,0.5)', /* Black background with opacity */
                         zIndex: 2, /* Specify a stack order in case you're using a different order for other elements */
                         cursor: 'pointer',
                     }}>

                </div>

                {this.props.children}


               

               
            </div>
    )
    }
    }
import React,{Component} from 'react';

import {Link} from 'react-router-dom';



export default class Footer extends Component{
    render(){
        return(
            <footer>
            <div className="container">
                <div className="row">

                    <div className="col-12">
                        <ul className="social_list">
                            <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                        </ul>

                        <p>© 2020 <Link to="#" className="white" target="_blank">CEBIC</Link>. All Rights
                            Reserved.</p>
                    </div>

                </div>
            </div>
        </footer>
        )
    }
}